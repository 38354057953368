import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
// import emailjs from "emailjs-com"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Header from "../components/Header"
import Footer from "../components/Footer/Footer"
import Content from "../components/Content"
import AccordionGreen from "../components/AccordionGreen"
import ReactMarkdown from "react-markdown"
import emailjs from "emailjs-com"
import $ from "jquery"
import axios from "axios"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { loadStripe } from "@stripe/stripe-js"
import Seo from "../components/SEO"
import { Modal } from "react-bootstrap"
import Invoice from "../components/Invoice"

const CustomQuote = ({ data }) => {
  // const [contactName, setContactName] = useState("")
  // const [contactEmail, setContactEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [mgmtPlanOption, setMgmtPlanOption] = useState("")
  const [projPlanOption, setProjPlanOption] = useState("")
  const [contractTerm, setContractTerm] = useState("Annually")
  const [duration, setDuration] = useState(1)
  const [subEndDate, setSubEndDate] = useState("")
  const [amount, setAmount] = useState(0)
  const [invoiceURL, setInvoiceURL] = useState("")
  const [showPopup, setShowPopup] = useState(false)
  const [showInvoice, setShowInvoice] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  const {
    allContentfulTestDriveNew,
    allContentfulTogglesCustomQuotePage,
    allContentfulCustomQuoteContentArea,
    allContentfulCustomQuoteForm,
    allContentfulCustomQuotePrices,
    allContentfulHeader,
    allContentfulSeo,
    allContentfulInvoice,
  } = data

  useEffect(() => {
    // center popup box on screen
    $(".modal-dialog").addClass("modal-dialog-centered")
  })

  useEffect(() => {
    // click on one of the option boxes of the management plan
    $(".mgmt-box").click(function () {
      // change background color and text color
      $(this).children("input").prop("checked", true)
      $(".mgmt-box").removeClass("selected")
      $(this).toggleClass("selected")
      // set value of management plan option
      setMgmtPlanOption($(this).children("input").val())
    })

    // click on one of the option boxes of the project plan
    $(".proj-box").click(function () {
      // change background color and text color
      $(this).children("input").prop("checked", true)
      $(".proj-box").removeClass("selected")
      $(this).toggleClass("selected")
      // set value of project plan option
      setProjPlanOption($(this).children("input").val())
    })

    // click on either box of the contract term options
    $(".billing-box").click(function () {
      // change background color and text color
      $(this).children("input").prop("checked", true)
      $(".billing-box").removeClass("selected")
      $(this).toggleClass("selected")
      // set value of duration text
      if ($(this).children("input").attr("id") === "annually") {
        $("#duration-span").html("YEARS")
      } else if ($(this).children("input").attr("id") === "monthly") {
        $("#duration-span").html("MONTHS")
      }
      // set value of contract term
      setContractTerm($(this).children("input").val())
    })
  })

  const getTotalAmount = useCallback(() => {
    let amount = 0
    // if billed monthly:
    if (contractTerm === "Monthly") {
      if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionOne
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly3users1projs // for option of 3 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly3users3projs // for option of 3 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly3users10projs // for option of 3 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly3users25projs // for option of 3 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionTwo
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly10users1projs // for option of 10 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly10users3projs // for option of 10 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly10users10projs // for option of 10 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly10users25projs // for option of 10 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionThree
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly20users1projs // for option of 20 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly20users3projs // for option of 20 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly20users10projs // for option of 20 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly20users25projs // for option of 20 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionFour
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly50users1projs // for option of 50 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly50users3projs // for option of 50 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly50users10projs // for option of 50 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].monthly50users25projs // for option of 50 users and 25 projects
        }
      }
    }
    // if billed annually:
    else if (contractTerm === "Annually") {
      if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionOne
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually3users1projs // for option of 3 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually3users3projs // for option of 3 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually3users10projs // for option of 3 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually3users25projs // for option of 3 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionTwo
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually10users1projs // for option of 10 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually10users3projs // for option of 10 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually10users10projs // for option of 10 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually10users25projs // for option of 10 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionThree
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually20users1projs // for option of 20 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually20users3projs // for option of 20 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually20users10projs // for option of 20 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually20users25projs // for option of 20 users and 25 projects
        }
      } else if (
        mgmtPlanOption ===
        allContentfulCustomQuoteForm.nodes[0].planOneOptionFour
      ) {
        if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually50users1projs // for option of 50 users and 1 project
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo
        ) {
          amount = allContentfulCustomQuotePrices.nodes[0].annually50users3projs // for option of 50 users and 3 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually50users10projs // for option of 50 users and 10 projects
        } else if (
          projPlanOption ===
          allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
        ) {
          amount =
            allContentfulCustomQuotePrices.nodes[0].annually50users25projs // for option of 50 users and 25 projects
        }
      }
    }
    // return total amount
    return amount * duration
  }, [
    mgmtPlanOption,
    projPlanOption,
    contractTerm,
    duration,
    allContentfulCustomQuoteForm.nodes,
    allContentfulCustomQuotePrices.nodes,
  ])

  useEffect(() => {
    // set value of total amount
    setAmount(getTotalAmount())
  }, [getTotalAmount])

  const isLeapYear = year => {
    // check if a year is leap year by checking if February contains 29 days
    return new Date(year, 1, 29).getDate() === 29
  }

  const calculateSubEndDate = useCallback(() => {
    const smallMonths = [4, 6, 9, 11] // months that have 30 days
    // get current year, month, date
    let startFullDate = new Date()
    let startYear = startFullDate.getFullYear()
    let startMonth = startFullDate.getMonth() + 1 // month range 0-11
    let startDate = startFullDate.getDate()
    // calculate sub end year, month, date
    let endYear, endMonth, endDate
    // if duration in years:
    if (contractTerm === "Annually") {
      endYear = startYear + duration
      endMonth = startMonth
      // if current date is Feb 29th, check if end year is leap year to determine end date
      if (startMonth === 2 && startDate === 29) {
        endDate = isLeapYear(endYear) ? 29 : 28
      } else {
        endDate = startDate
      }
    }
    // if duration in months:
    else if (contractTerm === "Monthly") {
      endYear = startYear + Math.floor((startMonth + duration) / 12)
      endMonth = (startMonth + duration) % 12
      // if current date is the 31th and end month only has 30 days, end date is the 30th
      if (startDate === 31 && smallMonths.includes(endMonth)) {
        endDate = 30
      }
      // if current date is 29th, 30th or 31th and end month is Feb,
      // check if end year is leap year to determine end date
      else if (startDate >= 29 && endMonth === 2) {
        endDate = isLeapYear(endYear) ? 29 : 28
      } else {
        endDate = startDate
      }
    }
    // pad end month with "0" if it is single-digit
    endMonth = endMonth.toString()
    endMonth = endMonth.length === 1 ? "0" + endMonth : endMonth
    // pad end date with "0" if it is single-digit
    endDate = endDate.toString()
    endDate = endDate.length === 1 ? "0" + endDate : endDate
    // return sub end date as string
    return endYear + "/" + endMonth + "/" + endDate
  }, [contractTerm, duration])

  useEffect(() => {
    // set value of sub end date after calculation
    setSubEndDate(calculateSubEndDate())
  }, [calculateSubEndDate])

  const resetQuoteForm = () => {
    // reset form data
    $("#customQuoteForm").trigger("reset")
    // reset initial state of contract term options
    $("#duration-span").html("YEARS")
    setContractTerm("Annually")
    // change option boxes back to original style
    $(".mgmt-box,.proj-box,.billing-box").removeClass("selected")
    // hide custom quote popup
    togglePopup()
    // hide copy success message
    setCopySuccess(false)
  }

  const togglePopup = () => {
    setShowPopup(!showPopup)
    return showPopup
  }

  const toggleInvoice = () => {
    setShowInvoice(!showInvoice)
    return showInvoice
  }

  useEffect(() => {
    // display copy success message
    if (copySuccess) {
      window.setTimeout(function () {
        $("#copy-success")
          .fadeTo(500, 0)
          .slideUp(500, function () {
            $("#copy-success").hide()
            setCopySuccess(false)
          })
      }, 2000)
    }
  }, [copySuccess])

  const copyUrl = () => {
    return (
      <CopyToClipboard text={invoiceURL} onCopy={() => setCopySuccess(true)}>
        <button className="btn" type="button">
          {allContentfulCustomQuoteForm.nodes[0].popupButtonOne}
        </button>
      </CopyToClipboard>
    )
  }

  const copySuccessMessage = () => {
    return (
      <div className="alert alert-success" id="copy-success" role="alert">
        {allContentfulCustomQuoteForm.nodes[0].popupCopySuccess}
      </div>
    )
  }

  // const goToInvoice = () => {
  //   return (
  //     <button
  //       className="btn"
  //       type="button"
  //       onClick={() => window.open(invoiceURL)}
  //     >
  //       {allContentfulCustomQuoteForm.nodes[0].popupButtonTwo}
  //     </button>
  //   )
  // }

  // const downloadQuote = () => {
  //   return (
  //     <button className="btn" type="button">
  //       {allContentfulCustomQuoteForm.nodes[0].popupButtonTwo}
  //     </button>
  //   )
  // }

  const openQuote = () => {
    return (
      <button
        className="btn"
        type="button"
        onClick={() => {
          toggleInvoice()
          togglePopup()
        }}
      >
        {allContentfulCustomQuoteForm.nodes[0].popupButtonThree}
      </button>
    )
  }

  const createInvoiceBox = () => {
    return (
      <Invoice
        toggleInvoice={() => toggleInvoice()}
        togglePopup={() => togglePopup()}
        invoiceLogo={allContentfulHeader.nodes[0].logoBlue.file.url}
        titleOfQuote={allContentfulInvoice.nodes[0].titleOfQuote}
        invoiceURL={invoiceURL.substring(invoiceURL.length - 12)}
        serviceDescription={allContentfulInvoice.nodes[0].serviceDescription}
        fullName={fullName}
        email={email}
        companyName={companyName}
        serviceNote={allContentfulInvoice.nodes[0].serviceNote}
        mgmtPlanOption={mgmtPlanOption}
        projPlanOption={projPlanOption}
        contractTerm={contractTerm}
        duration={duration}
        amount={amount}
        subEndDate={subEndDate}
        oneTimeNote={allContentfulInvoice.nodes[0].oneTimeNote}
        oneTimeAmount={allContentfulInvoice.nodes[0].oneTimeAmount}
        vatPercentage={allContentfulInvoice.nodes[0].vat}
      />
    )
  }

  const createPopupBox = () => {
    return (
      <Modal
        show={showPopup}
        onHide={() => resetQuoteForm()}
        className="custom-quote-popup main-text-color"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {allContentfulCustomQuoteForm.nodes[0].popupHeader}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-buttons">
            {copyUrl()}
            {openQuote()}
          </div>
          {copySuccess ? copySuccessMessage() : null}
        </Modal.Body>
      </Modal>
    )
  }

  const handleSubmitQuoteForm = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_qkbep48",
        "template_wl24ize",
        e.target,
        "user_H1giHzeT00K7WW4tInQOn"
      )
      .then(
        result => {
          console.log(result)
        },
        error => {
          console.log(error.text)
        }
      )

    const serviceDescription = allContentfulInvoice.nodes[0].serviceDescription
    const serviceNote = allContentfulInvoice.nodes[0].serviceNote
    const oneTimeNote = allContentfulInvoice.nodes[0].oneTimeNote
    const titleOfQuote = allContentfulInvoice.nodes[0].titleOfQuote
    const oneTimeAmount = allContentfulInvoice.nodes[0].oneTimeAmount
    const vat = allContentfulInvoice.nodes[0].vat

    let quoteFormData = new FormData()
    quoteFormData.append("fullname", fullName)
    quoteFormData.append("companyname", companyName)
    quoteFormData.append("email", email)
    quoteFormData.append("mgmt_plan_option", mgmtPlanOption)
    quoteFormData.append("proj_plan_option", projPlanOption)
    quoteFormData.append("title_of_quote", titleOfQuote)
    quoteFormData.append("servicedescription", serviceDescription)
    quoteFormData.append("contractterm", contractTerm)
    quoteFormData.append("duration", duration)
    quoteFormData.append("sub_end_date", subEndDate)
    quoteFormData.append("amount", amount)
    quoteFormData.append("servicenote", serviceNote)
    quoteFormData.append("OneTimeAmount", oneTimeAmount)
    quoteFormData.append("onetimenote", oneTimeNote)
    quoteFormData.append("vat", vat)

    axios({
      method: "post",
      url: "https://quotedandpay.xiir.com/submit-invoice.php",
      data: quoteFormData,
    })
      .then(response => {
        console.log("Submitted")
        console.log(response)
        let url = "https://quotedandpay.xiir.com/" + response.data
        console.log("Invoice URL: " + url)
        setInvoiceURL(url)
        togglePopup()
      })
      .catch(error => {
        console.log(error)
      })
  }

  // const handleSubmitContactForm = e => {
  //   e.preventDefault()
  //   emailjs
  //     .sendForm(
  //       "service_qkbep48",
  //       "template_wl24ize",
  //       e.target,
  //       "user_H1giHzeT00K7WW4tInQOn"
  //     )
  //     .then(
  //       result => {
  //         console.log(result)
  //       },
  //       error => {
  //         console.log(error.text)
  //       }
  //     )
  //   emailjs
  //     .sendForm(
  //       "service_qkbep48",
  //       "template_8hke6d4",
  //       e.target,
  //       "user_H1giHzeT00K7WW4tInQOn"
  //     )
  //     .then(
  //       result => {
  //         console.log(result)
  //       },
  //       error => {
  //         console.log(error.text)
  //       }
  //     )
  //   setContactName("")
  //   setContactEmail("")
  // }

  return (
    <div>
      <Seo
        title={allContentfulSeo.nodes[7].title}
        description={allContentfulSeo.nodes[7].description}
        keywords={allContentfulSeo.nodes[7].keywords}
      />
      <Header />
      <section className="xiir-benefits contact-section">
        <div className="container">
          <div className="div-head">
            <h3 className="headings-of-all">
              {allContentfulTestDriveNew.nodes[0].title}
            </h3>
            <h3 className="ser-head-2">
              {allContentfulTestDriveNew.nodes[0].subTitle}
            </h3>
          </div>
          <div className="div-para">
            <h4>
              <ReactMarkdown
                children={allContentfulTestDriveNew.nodes[0].subDescription}
              />
            </h4>
          </div>
        </div>
      </section>

      <div className="container d-flex flex-wrap text-center">
        <div className="custom-quote-div">
          <form onSubmit={handleSubmitQuoteForm} id="customQuoteForm">
            <h3>{allContentfulCustomQuoteForm.nodes[0].title}</h3>
            <div className="form-group">
              <input
                type="text"
                className="custom-quote-input"
                placeholder={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldOne
                }
                aria-label={allContentfulCustomQuoteForm.nodes[0].inputFieldOne}
                name="full_name"
                onChange={e => setFullName(e.currentTarget.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="custom-quote-input"
                placeholder={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldTwo
                }
                aria-label={allContentfulCustomQuoteForm.nodes[0].inputFieldTwo}
                name="company_name"
                onChange={e => setCompanyName(e.currentTarget.value)}
                required
              />
            </div>
            <div className="form-group" style={{ marginBottom: 0 }}>
              <input
                type="email"
                className="custom-quote-input"
                placeholder={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldThree
                }
                aria-label={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldThree
                }
                name="email"
                onChange={e => setEmail(e.currentTarget.value)}
                required
              />
            </div>

            <img
              src={
                allContentfulCustomQuoteForm.nodes[0].choosePlanIconOne.file.url
              }
              alt="Choose Management Plan Icon"
            />
            <h4>{allContentfulCustomQuoteForm.nodes[0].choosePlanHeaderOne}</h4>
            <p>{allContentfulCustomQuoteForm.nodes[0].textSelectOption}</p>
            <div className="d-flex custom-quote-options">
              <div className="form-check form-check-inline d-flex flex-column mgmt-box">
                <label className="form-check-label" htmlFor="mgmt1">
                  {allContentfulCustomQuoteForm.nodes[0].planOneOptionOne}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="mgmt_plan_option"
                  id="mgmt1"
                  value={allContentfulCustomQuoteForm.nodes[0].planOneOptionOne}
                  required
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column mgmt-box">
                <label className="form-check-label" htmlFor="mgmt2">
                  {allContentfulCustomQuoteForm.nodes[0].planOneOptionTwo}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="mgmt_plan_option"
                  id="mgmt2"
                  value={allContentfulCustomQuoteForm.nodes[0].planOneOptionTwo}
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column mgmt-box">
                <label className="form-check-label" htmlFor="mgmt3">
                  {allContentfulCustomQuoteForm.nodes[0].planOneOptionThree}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="mgmt_plan_option"
                  id="mgmt3"
                  value={
                    allContentfulCustomQuoteForm.nodes[0].planOneOptionThree
                  }
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column mgmt-box">
                <label className="form-check-label" htmlFor="mgmt4">
                  {allContentfulCustomQuoteForm.nodes[0].planOneOptionFour}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="mgmt_plan_option"
                  id="mgmt4"
                  value={
                    allContentfulCustomQuoteForm.nodes[0].planOneOptionFour
                  }
                />
              </div>
            </div>

            <img
              src={
                allContentfulCustomQuoteForm.nodes[0].choosePlanIconTwo.file.url
              }
              alt="Choose Project Plan Icon"
            />
            <h4>{allContentfulCustomQuoteForm.nodes[0].choosePlanHeaderTwo}</h4>
            <p>{allContentfulCustomQuoteForm.nodes[0].textSelectOption}</p>
            <div className="d-flex custom-quote-options">
              <div className="form-check form-check-inline d-flex flex-column proj-box">
                <label className="form-check-label" htmlFor="proj1">
                  {allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="proj_plan_option"
                  id="proj1"
                  value={allContentfulCustomQuoteForm.nodes[0].planTwoOptionOne}
                  required
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column proj-box">
                <label className="form-check-label" htmlFor="proj2">
                  {allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="proj_plan_option"
                  id="proj2"
                  value={allContentfulCustomQuoteForm.nodes[0].planTwoOptionTwo}
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column proj-box">
                <label className="form-check-label" htmlFor="proj3">
                  {allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="proj_plan_option"
                  id="proj3"
                  value={
                    allContentfulCustomQuoteForm.nodes[0].planTwoOptionThree
                  }
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column proj-box">
                <label className="form-check-label" htmlFor="proj4">
                  {allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="proj_plan_option"
                  id="proj4"
                  value={
                    allContentfulCustomQuoteForm.nodes[0].planTwoOptionFour
                  }
                />
              </div>
            </div>

            <img
              src={
                allContentfulCustomQuoteForm.nodes[0].choosePlanIconThree.file
                  .url
              }
              alt="Billing Options Icon"
            />
            <h4>
              {allContentfulCustomQuoteForm.nodes[0].choosePlanHeaderThree}
            </h4>
            <div className="d-flex custom-quote-options" id="contract-term-div">
              <div className="form-check form-check-inline d-flex flex-column billing-box">
                <label className="form-check-label" htmlFor="annually">
                  {allContentfulCustomQuoteForm.nodes[0].billingOptionOne}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="contract_term_option"
                  id="annually"
                  value={allContentfulCustomQuoteForm.nodes[0].billingOptionOne}
                  required
                />
              </div>
              <div className="form-check form-check-inline d-flex flex-column billing-box">
                <label className="form-check-label" htmlFor="monthly">
                  {allContentfulCustomQuoteForm.nodes[0].billingOptionTwo}
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="contract_term_option"
                  id="monthly"
                  value={allContentfulCustomQuoteForm.nodes[0].billingOptionTwo}
                />
              </div>
            </div>
            <div className="input-group" id="duration-div">
              <input
                type="number"
                className="form-control"
                name="duration"
                placeholder={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldFour
                }
                aria-label={
                  allContentfulCustomQuoteForm.nodes[0].inputFieldFour
                }
                min="1"
                onChange={e => setDuration(parseInt(e.currentTarget.value))}
                required
              />
              <div className="input-group-append">
                <span className="input-group-text" id="duration-span">
                  YEARS
                </span>
              </div>
            </div>

            <button type="submit" className="btn btn-purple">
              {allContentfulCustomQuoteForm.nodes[0].button}
            </button>
          </form>
          {showPopup ? createPopupBox() : null}
          {showInvoice ? createInvoiceBox() : null}
        </div>
      </div>

      <div className="section-padding-2">
        <Content
          main1={
            allContentfulCustomQuoteContentArea.nodes[0].textMain1.textMain1
          }
          main2={
            allContentfulCustomQuoteContentArea.nodes[0].textMain2.textMain2
          }
          side1={
            allContentfulCustomQuoteContentArea.nodes[0].textSide1.textSide1
          }
          side2={
            allContentfulCustomQuoteContentArea.nodes[0].textSide2.textSide2
          }
          className="gr-bg"
        />
      </div>
      <section className="section-padding d-flex">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="request-custom-quote">
                  {allContentfulTogglesCustomQuotePage.nodes.map((v, i) => (
                    <AccordionGreen
                      title={v.title}
                      content={documentToReactComponents(
                        JSON.parse(v.description.raw)
                      )}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="download-box section-padding">
        <div className="container">
          <div className="our-media">
            <h3 className="ser-head-2">
              {allContentfulTestDriveNew.nodes[0].inputTitle}
            </h3>
          </div>
          <div className="box">
            <div className="datasheet-down">
              <form onSubmit={handleSubmitContactForm}>
                <div className="input">
                  <input
                    placeholder="full name"
                    value={contactName}
                    type="text"
                    name="contact_name"
                    style={{ marginBottom: "10px" }}
                    onChange={e => setContactName(e.currentTarget.value)}
                  />
                  <input
                    placeholder="email"
                    value={contactEmail}
                    type="email"
                    name="contact_email"
                    style={{ marginBottom: "10px" }}
                    onChange={e => setContactEmail(e.currentTarget.value)}
                  />
                </div>
                <br />
                <div>
                  <button type="submit" className="btn btn-purple">
                    submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>*/}
      <Footer />
    </div>
  )
}

export default CustomQuote

export const query = graphql`
  query {
    allContentfulTestDriveNew {
      nodes {
        title
        description {
          description
        }
        subTitle
        subDescription
        inputTitle
        mainText
        planTwo {
          file {
            url
          }
        }
        planOne {
          file {
            url
          }
        }
        commingSoonImg {
          file {
            url
          }
        }
      }
    }
    allContentfulCustomQuoteContentArea(sort: { fields: createdAt }) {
      nodes {
        textMain1 {
          textMain1
        }
        textMain2 {
          textMain2
        }
        textSide1 {
          textSide1
        }
        textSide2 {
          textSide2
        }
      }
    }
    allContentfulTogglesCustomQuotePage(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        title
        description {
          raw
        }
      }
    }
    allContentfulTestDrivePlans(sort: { fields: createdAt }) {
      nodes {
        icon {
          file {
            url
          }
        }
        header
        title
        description {
          description
        }
        pricing
        checkboxText
        recommendText
        taxText
      }
    }
    allContentfulInvoice {
      nodes {
        serviceDescription
        serviceNote
        oneTimeNote
        titleOfQuote
        oneTimeAmount
        vat
      }
    }
    allContentfulCustomQuoteForm {
      nodes {
        title
        inputFieldOne
        inputFieldTwo
        inputFieldThree
        inputFieldFour
        choosePlanHeaderOne
        choosePlanHeaderTwo
        choosePlanHeaderThree
        choosePlanIconOne {
          file {
            url
          }
        }
        choosePlanIconTwo {
          file {
            url
          }
        }
        choosePlanIconThree {
          file {
            url
          }
        }
        textSelectOption
        planOneOptionOne
        planOneOptionTwo
        planOneOptionThree
        planOneOptionFour
        planTwoOptionOne
        planTwoOptionTwo
        planTwoOptionThree
        planTwoOptionFour
        billingOptionOne
        billingOptionTwo
        button
        popupHeader
        popupButtonOne
        popupButtonTwo
        popupButtonThree
        popupCopySuccess
      }
    }
    allContentfulCustomQuotePrices {
      nodes {
        monthly3users1projs
        monthly3users3projs
        monthly3users10projs
        monthly3users25projs
        monthly10users1projs
        monthly10users3projs
        monthly10users10projs
        monthly10users25projs
        monthly20users1projs
        monthly20users3projs
        monthly20users10projs
        monthly20users25projs
        monthly50users1projs
        monthly50users3projs
        monthly50users10projs
        monthly50users25projs
        annually3users1projs
        annually3users3projs
        annually3users10projs
        annually3users25projs
        annually10users1projs
        annually10users3projs
        annually10users10projs
        annually10users25projs
        annually20users1projs
        annually20users3projs
        annually20users10projs
        annually20users25projs
        annually50users1projs
        annually50users3projs
        annually50users10projs
        annually50users25projs
      }
    }
    allContentfulHeader {
      nodes {
        btnOne
        logoBlue {
          file {
            url
          }
        }
      }
    }
    allContentfulSeo(sort: { fields: createdAt }) {
      nodes {
        title
        description
        keywords
      }
    }
  }
`
